import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
	getDeletePartner,
	getPartnersList,
} from '../../../../../redux/adminUsers&Partners_Reducer'
import AdminPartnersTable from '../AdminPartnersTable'

const PartnerViewContainer = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const partnersList = useSelector(state => state.admin.adminPartnerListData)
	const pagination = useSelector(
		state => state.admin.adminPartnerListMeta.pagination
	)

	useEffect(() => {
		dispatch(getPartnersList(1, 15, ))
	}, [dispatch])

	// const handlePageChange = useCallback(
	// 	page => {
	// 		dispatch(getPartnersList(page))
	// 	},
	// 	[dispatch]
	// )

	const getDelete = useCallback(
		id => {
			dispatch(getDeletePartner(id))
		},
		[dispatch]
	)

	const createNavLink = '/admin_cabinet/partners/create'

	console.log('pagination', pagination)

	return (
		<AdminPartnersTable
			rowsList={partnersList}
			getServices={getPartnersList}
			deleteButton={getDelete}
			createNavLink={createNavLink}
			pageTotalCount={pagination?.total}
			type={'service'}
			isShowSearchBlock={true}
			allSearchList={partnersList}
			// rowsList={partnersList}
			// current_page={pagination?.current_page}
			// per_page={pagination?.per_page}
			// deleteButton={getDelete}
			// createNavLink={createNavLink}
			// pageTotalCount={pagination?.total}
			// getServices={getPartnersList}
			// // onPageChange={handlePageChange}
		/>
	)
}

export default PartnerViewContainer
