import { adminPartner, adminUser } from '../api/apiAdmin'

const SET_USERS_LIST = 'tour2sky/admin/users/SET_USERS_LIST'
const SET_USER_VIEW = 'tour2sky/admin/users/SET_USER_VIEW'
const SET_USER_ACTIVATE = 'tour2sky/admin/users/SET_USER_ACTIVATE'

const SET_LOADING_ON = 'tour2sky/admin/users/SET_LOADING_ON'
const SET_LOADING_OFF = 'tour2sky/admin/users/SET_LOADING_OFF'

const SET_PARTNERS_LIST = 'tour2sky/admin/PartnerClientCabinet/SET_PARTNER_LIST'
const SET_PARTNER_VIEW = 'tour2sky/admin/PartnerClientCabinet/SET_PARTNER_VIEW'
const SET_DELETE = 'tour2sky/admin/Partner/SET_DELETE'
const SET_PARTNER_SELECT = 'tour2sky/admin/Partner/SET_PARTNER_SELECT'
const SET_PARTNER_TOURS = 'tour2sky/admin/Partner/SET_PARTNER_TOURS'

const SET_ADMIN = 'tour2sky/admin/PartnerClientCabinet/SET_ADMIN'
const SET_NO_ADMIN = 'tour2sky/admin/PartnerClientCabinet/SET_NO_ADMIN'

const initialState = {
	adminUsersListData: [],
	adminUsersListMeta: {},
	adminUserView: {
		id: 1,
		email: 'johndoe@gmail.com',
		name: 'John',
		surname: 'Doe',
		phone: '+380111111111',
		type: 1,
		status: 1,
		created_at: '2021-12-25 09:30:25',
		subscribe_news: true,
		statusObject: {
			id: 2,
			name: 'Active',
		},
		typeObject: {
			id: 1,
			name: 'Client',
		},
	},
	partnerTours: [],
	selectPartners: [],
	adminPartnerListData: [
		// {
		// 	id: 1,
		// 	company_name: 'Google',
		// 	created_at: '2021-12-25 09:30:25',
		// 	status: 1,
		// 	statusObject: {
		// 		id: 2,
		// 		name: 'Active',
		// 	},
		// },
	],
	adminPartnerListMeta: { pagination: { total: 1, current_page: 1 } },
	adminPartnerView: {
		// id: 1,
		// email: 'johndoe@gmail.com',
		// name: 'John',
		// surname: 'Doe',
		// phone: '+380111111111',
		// type: 1,
		// company_name: 'Google',
		// company_type: 1,
		// created_at: '2021-12-25 09:30:25',
		// subscribe_news: true,
		// statusObject: {
		// 	id: 2,
		// 	name: 'Active',
		// },
		// typeObject: {
		// 	id: 1,
		// 	name: 'Client',
		// },
		// companyTypeObject: {
		// 	id: 2,
		// 	name: 'Private person',
		// },
	},
	isLoading: false,
}

const adminUsersPartners_Reducer = (state = initialState, action) => {
	console.log('action', action)
	switch (action.type) {
		case SET_USERS_LIST: {
			return {
				...state,
				adminUsersListData: action.data.data,
				adminUsersListMeta: action.data,
			}
		}
		case SET_USER_VIEW: {
			return {
				...state,
				adminUserView: action.data,
			}
		}
		case SET_USER_ACTIVATE: {
			return {
				...state,
				adminUserActivate: action.data,
			}
		}
		/*=============================================================*/
		case SET_PARTNERS_LIST: {
			return {
				...state,
				adminPartnerListData: action.data.data,
				adminPartnerListMeta: action.data.meta,
			}
		}
		case SET_PARTNER_VIEW: {
			return {
				...state,
				adminPartnerView: action.data,
			}
		}
		case SET_LOADING_ON: {
			return {
				...state,
				isLoading: true,
			}
		}
		case SET_LOADING_OFF: {
			return {
				...state,
				isLoading: false,
			}
		}
		/*==================================================*/
		case SET_ADMIN: {
			return {
				...state,
				adminUserView: { ...state.adminUserView, role: 'admin' },
			}
		}
		case SET_NO_ADMIN: {
			return {
				...state,
				adminUserView: { ...state.adminUserView, role: 'client' },
			}
		}

		case SET_DELETE: {
			return {
				...state,
				adminPartnerListData: state.adminPartnerListData.filter(
					item => item.id !== action.data
				),
			}
		}
		case SET_PARTNER_SELECT: {
			return {
				...state,
				selectPartners: action.data,
			}
		}
		case SET_PARTNER_TOURS: {
			return {
				...state,
				partnerTours: action.data,
			}
		}

		default:
			return state
	}
}

export const setUsersList = data => ({ type: SET_USERS_LIST, data })
export const setUserView = data => ({ type: SET_USER_VIEW, data })
export const setPartnersList = data => ({ type: SET_PARTNERS_LIST, data })
export const setPartnerView = data => ({ type: SET_PARTNER_VIEW, data })
export const setUserActivate = data => ({ type: SET_USER_ACTIVATE, data })

export const setLoadingOn = () => ({ type: SET_LOADING_ON })
export const setLoadingOff = () => ({ type: SET_LOADING_OFF })

export const setAdminOn = () => ({ type: SET_ADMIN })
export const setAdminOff = () => ({ type: SET_NO_ADMIN })
export const setDeletePartner = data => ({ type: SET_DELETE, data })
export const setPartnerSelection = data => ({ type: SET_PARTNER_SELECT, data })
export const setPartnerTours = data => ({ type: SET_PARTNER_TOURS, data })

/* Thunk*/

export const getUsersList = (page, perPage, sortFullName) => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminUser.getAdminUsersList(
			page,
			perPage,
			sortFullName
		)
		dispatch(setUsersList(response.data))
	} catch (e) {
		console.log(e)
	}
	dispatch(setLoadingOff())
}
export const getAdminUserView = userId => async dispatch => {
	dispatch(setLoadingOn())
	try {
		let response = await adminUser.getAdminUserView(userId)
		dispatch(setUserView(response.data))
	} catch (e) {}
	dispatch(setLoadingOff())
}
export const getActivateUser = userId => async dispatch => {
	let response = await adminUser.getAdminUserActivate(userId)
	dispatch(setUserView(response.data))
}
export const getBlockUser = userId => async dispatch => {
	let response = await adminUser.getAdminUserBlock(userId)
	dispatch(setUserView(response.data))
}
/*===============================================================================*/

export const getPartnersList = (page, perPage, order) => async dispatch => {
	let response = await adminPartner.getAdminPartnersList(page, perPage, order)
	console.log('response', response)
	dispatch(setPartnersList(response.data))
}
export const getPartnerView = userId => async dispatch => {
	let response = await adminPartner.getAdminPartnersView(userId)
	dispatch(setPartnerView(response.data))
}
export const createPartner =
	(formData, setStatus, setDone, partnerId) => async dispatch => {
		try {
			let response = await adminPartner.createPartner(formData, partnerId)
			console.log('response', response)
			dispatch(setPartnerView(response.data))
			setStatus(null)
			setDone(true)
		} catch (error) {
			let errors = error.response
			if (errors.status === 422) {
				setStatus({ error: errors.data.errors })
			}
		}
	}
export const getDeletePartner = partnerId => async dispatch => {
	try {
		await adminPartner.deletePartnder(partnerId)
		dispatch(setDeletePartner(partnerId))
	} catch (e) {
		console.log(e)
	}
}
export const getActivatePartner = userId => async dispatch => {
	let response = await adminPartner.getAdminPartnerActivate(userId)
	dispatch(setPartnerView(response.data))
}
export const getBlockPartner = userId => async dispatch => {
	let response = await adminPartner.getAdminPartnerBlock(userId)
	dispatch(setPartnerView(response.data))
}
export const getForSelectPartner = () => async dispatch => {
	try {
		const response = await adminPartner.getForSelect()
		console.log('response', response.data.result)
		dispatch(setPartnerSelection(response.data.result))
	} catch (e) {
		console.log(e)
	}
}
export const getPartnerTours = id => async dispatch => {
	try {
		const response = await adminPartner.getPartnerTours(id)
		console.log('response', response.data)
		dispatch(setPartnerTours(response.data.result))
	} catch (e) {
		console.log(e)
	}
}

/*=============================================================================*/
export const getAdminUserSetAdmin = userId => async dispatch => {
	dispatch(setLoadingOn())
	try {
		await adminUser.getAdminUserSetAdmin(userId)
		dispatch(setAdminOn())
	} catch (e) {}
	dispatch(setLoadingOff())
}
export const getAdminUserDelAdmin = userId => async dispatch => {
	dispatch(setLoadingOn())
	try {
		await adminUser.getAdminUserDelAdmin(userId)
		dispatch(setAdminOff())
	} catch (e) {}
	dispatch(setLoadingOff())
}

export default adminUsersPartners_Reducer
